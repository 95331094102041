import LR from "../../components/LeftRight.module.css";

import { notNull } from "@warrenio/utils/notNull";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WMeter } from "../../components/WMeter.tsx";
import { WSelect, type WSelectStyleProps, type WSelectValueProps } from "../../components/forms/WSelect.tsx";
import { WSelectWrapper } from "../../components/forms/WSelectWrapper.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { StatusBadge } from "./StatusBadge.tsx";
import type { AdminHostWithType } from "./hostQuery.ts";
import { adminHostQueryAtom } from "./hostQuery.ts";

export interface HostSelectProps extends WSelectStyleProps, WSelectValueProps<AdminHostWithType, string> {
    items: AdminHostWithType[];
    location: string;
    pool: string;
}

export function HostSelectCustom({ items, ...props }: HostSelectProps) {
    return (
        <WSelect
            itemClassName={LR.item}
            valueClassName={LR.value}
            aria-label="Select pool"
            items={items}
            getKey={(item) => item.uuid}
            getTextValue={(item) => item.name}
            searchItems
            placeholder="Any available"
            renderSelectedValue={(item) =>
                !!item && (
                    <LeftRight
                        icon={<MaskIcon className="jp-host-icon color-primary size-1rem" />}
                        title={item?.name}
                        right={<StatusBadge item={notNull(item)} />}
                    />
                )
            }
            {...props}
        >
            {(item) => (
                <LeftRight
                    icon={<MaskIcon className="jp-host-icon color-primary size-1rem" />}
                    title={
                        <div>
                            <div>{item.name}</div>
                            <div className="font-size-small color-muted">{item.ip}</div>
                        </div>
                    }
                    right={
                        <div className="HStack justify-end gap-0.5rem">
                            <div className="w-4rem">
                                <WMeter
                                    title="vCPU:"
                                    value={Number(((item.allocated_vcpu / item.usable_vcpu_amount) * 100).toFixed(0))}
                                />
                            </div>

                            <div className="w-4rem">
                                <WMeter
                                    title="vRAM:"
                                    value={Number(((item.allocated_vmem / item.usable_vmem_amount) * 100).toFixed(0))}
                                />
                            </div>
                        </div>
                    }
                />
            )}
        </WSelect>
    );
}

export interface HostSelectLoaderProps extends Omit<HostSelectProps, "items"> {}

function HostSelectLoader({ ...props }: HostSelectLoaderProps) {
    const data = useSuspenseQueryAtom(adminHostQueryAtom(props.location));
    const hosts = [...data.values()].filter((item) => item.pool_uuid === props.pool);
    return <HostSelectCustom items={hosts} defaultValueKey={props.valueKey} {...props} />;
}

export function HostSelect(props: HostSelectLoaderProps) {
    return (
        <WSelectWrapper fallback={(renderProps) => <HostSelectCustom {...props} {...renderProps} />}>
            <HostSelectLoader {...props} />
        </WSelectWrapper>
    );
}
